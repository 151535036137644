import { oneOf, string } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

const propTypes = {
  as: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  children: string,
}

const Title = ({ as = 'h3', children }) => {
  if (!children) return null

  return (
    <Typography
      as={as}
      color="neutral.90"
      dangerouslySetInnerHTML={{ __html: children }}
      variant={{
        _: 'headingLgRegular',
        '5xl': 'heading2xlRegular',
      }}
    />
  )
}

Title.propTypes = propTypes

export default Title
