import { bool, node } from 'prop-types'

import { Container, Grid, Wrapper } from './styles'

const propTypes = {
  children: node,
  hasExtraInfo: bool,
}

const Reason = ({ children, hasExtraInfo, ...others }) => {
  return (
    <Wrapper data-testid="reason" {...others}>
      <Container>
        <Grid hasExtraInfo={hasExtraInfo}>{children}</Grid>
      </Container>
    </Wrapper>
  )
}

Reason.propTypes = propTypes

export default Reason
