import { string } from 'prop-types'

import { Text } from './styles'

const propTypes = {
  children: string,
}

const Description = ({ children }) => {
  if (!children) return null

  return (
    <Text
      color="neutral.90"
      dangerouslySetInnerHTML={{ __html: children }}
      variant={{ _: 'bodyMdRegular', '5xl': 'bodyXlRegular' }}
    />
  )
}

Description.propTypes = propTypes

export default Description
