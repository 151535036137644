import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container } from 'components/Container'
import { Grid as WebsiteGrid } from 'components/Grid'

const Wrapper = styled.div`
  min-height: 232px;
  padding-top: 48px;
  padding-bottom: 64px;

  ${media.up('5xl')} {
    min-height: 260px;
    padding-top: 72px;
    padding-bottom: 72px;
  }

  &:nth-of-type(3n - 2) {
    background-color: transparent;
  }

  &:nth-of-type(3n - 1) {
    background-color: ${selectTheme('colors.neutral.15')};
  }

  &:nth-of-type(3n) {
    background-color: ${selectTheme('colors.neutral.20')};
  }
`

const Grid = styled(WebsiteGrid)(
  ({ hasExtraInfo }) => css`
    ${media.down('5xl')} {
      grid-row-gap: 48px;
      grid-template-columns: 1fr;
    }

    ${media.up('5xl')} {
      grid-template-columns: repeat(${hasExtraInfo ? 2 : 1}, 1fr);
    }
  `,
)

export { Wrapper, Container, Grid }
