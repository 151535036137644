import { string } from 'prop-types'

import { Text, Wrapper } from './styles'

const propTypes = {
  children: string,
}

const ExtraInfos = ({ children }) => {
  if (!children) return null

  return (
    <Wrapper>
      <Text
        as="span"
        color="neutral.90"
        dangerouslySetInnerHTML={{ __html: children }}
        variant={{
          _: 'bodySmRegular',
          '5xl': 'bodyMdRegular',
        }}
      />
    </Wrapper>
  )
}

ExtraInfos.propTypes = propTypes

export default ExtraInfos
