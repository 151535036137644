import { arrayOf, shape, string } from 'prop-types'

import Description from './components/Description'
import ExtraInfos from './components/ExtraInfos'
import MainInfos from './components/MainInfos'
import Reason from './components/Reason'
import Title from './components/Title'
import Wrapper from './components/Wrapper'

const propTypes = {
  reasons: arrayOf(
    shape({
      id: string,
      description: string,
      extraInfo: string,
      title: string,
      titleHeadingType: string,
    }),
  ),
}

const WhyTrustUs = ({ reasons, ...others }) => (
  <Wrapper {...others}>
    {reasons?.map(({ description, extraInfo, id, title, titleHeadingType }) => (
      <Reason hasExtraInfo={!!extraInfo} key={id}>
        <MainInfos>
          <Title as={titleHeadingType}>{title}</Title>
          <Description>{description}</Description>
        </MainInfos>
        <ExtraInfos>{extraInfo}</ExtraInfos>
      </Reason>
    ))}
  </Wrapper>
)

WhyTrustUs.propTypes = propTypes

export default WhyTrustUs
