import { styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { media, selectTheme } from '@creditas-ui/utilities'

const Wrapper = styled.div`
  display: flex;

  ${media.up('5xl')} {
    justify-content: flex-end;
  }
`

const Text = styled(Typography)`
  ${media.up('5xl')} {
    text-align: right;
    margin-top: 8%;
  }

  a {
    color: ${selectTheme('colors.primary.60')};
    text-underline-position: under;
  }
`

export { Text, Wrapper }
